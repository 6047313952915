/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";

import styled from "@emotion/styled";

import { IconKeys, PulseIcon } from "@fllite/ui/components";

const Box = styled("article")`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px;
  &:first-of-type {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    width: 33.333%;
  }
`;

const Subtitle = styled("h3")`
  font-size: 16px;
  text-align: center;
  margin-block-start: 0;
  margin-block-end: 0;
  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    margin-block-start: 0.5em;
  }
`;

const Description = styled("p")`
  font-size: 14px;
  text-align: center;
  line-height: 2.6rem;
`;

export interface BenefitBoxProps {
  icon: IconKeys;
  title: string;
  description: string | JSX.Element;
  testId: string;
  bigIcon?: boolean;
}

export const BenefitBox: FC<BenefitBoxProps> = ({
  icon,
  title,
  description,
  testId,
  bigIcon,
}) => (
  <Box>
    <PulseIcon icon={icon} size={bigIcon ? 80 : 40} />
    <Subtitle data-testid={`h3-${testId}`}>{title}</Subtitle>
    <Description data-testid={`${testId}-description`}>
      {description}
    </Description>
  </Box>
);
