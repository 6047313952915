/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from "@emotion/styled";

import { Cloud } from "@fllite/ui/components";

export const Container = styled("div")<{
  headerBg?: string;
  headerBgMobile?: string;
}>`
  position: relative;
  background: ${({ theme }) => (theme as any).colors.primaryLight};
  border-radius: 0 0 50% 50% / 0 0 80px 80px;

  @supports (clip-path: ellipse(100% 100% at 50% 0%)) {
    clip-path: ellipse(250% 100% at 50% 0%);
    ${({ theme }) => (theme as any).breakpoints.up("md")} {
      clip-path: ellipse(100% 100% at 50% 0%);
    }
    border-radius: 0;
  }

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 10px;
    background: url(${({ headerBg }) => headerBg || "/assets/header_bg.webp"});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 50%;
    background-color: #022366;
    border-radius: 0 0 50% 50% / 0 0 85px 85px;
    z-index: 0;
    ${({ theme }) => (theme as any).breakpoints.down("sm")} {
      background-image: url(${({ headerBgMobile }) =>
        headerBgMobile || "/assets/header_bg.webp"});
      background-position: center bottom;
    }

    @supports (clip-path: ellipse(105% 98% at 50% 0%)) {
      clip-path: ellipse(214% 98% at 50% 0%);
      ${({ theme }) => (theme as any).breakpoints.up("md")} {
        clip-path: ellipse(105% 98% at 50% 0%);
      }
      border-radius: 0;
      bottom: 0;
    }
  }
`;

export const Content = styled("div")`
  max-width: ${({ theme }) => (theme as any).dimensions.pageWidth};
  margin: 0 auto;
  padding: 0 20px;
  min-height: 800px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 32px 0 250px;
  ${({ theme }) => (theme as any).breakpoints.up("sm")} {
    padding-top: 150px;
  }
`;

export const HeadText = styled("div")`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding: 0 20px;
  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    padding: 0;
    margin-bottom: 32px;
  }
`;

export const Title = styled("h1")`
  font-size: 32px;
  line-height: 48px;
  color: ${({ theme }) => (theme as any).colors.baseFontColor};
  align-self: flex-start;
  font-weight: bold;
  margin-bottom: 16px;
  ${({ theme }) => (theme as any).breakpoints.up("sm")} {
    font-size: 40px;
  }
`;

export const SubTitle = styled("p")<{ color: string }>`
  font-size: 18px;
  line-height: 36px;
  color: ${({ theme, color }) => color || (theme as any).colors.primaryLight};
  align-self: flex-start;
  font-weight: bold;
  margin-block-start: 0;
  margin-block-end: 0;
  ${({ theme }) => (theme as any).breakpoints.up("sm")} {
    margin-block-start: 1em;
    font-size: 22px;
    margin-block-end: 1em;
  }
`;

export const Form = styled("div")`
  max-width: 100%;
  margin-bottom: 20px;
  width: 100%;
  padding: 0;
`;

export const JetImage = styled("img")`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  z-index: 1;
`;

export const Clouds = styled("div")`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 150px;
  pointer-events: none;
`;

export const StyledCloud = styled(Cloud as any)`
  position: absolute;

  ${({ theme }) => (theme as any).breakpoints.down("md")} {
    width: 350px;
    max-width: 400px;
  }
`;

export const CloudWrapper = styled("div")`
  position: absolute;
`;

export const HeaderWrapper = styled("div")`
  position: relative;
`;
