/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from "@emotion/styled";
import { Box } from "@mui/material";

const Container = styled("article")`
  margin-bottom: 24px;
  max-width: 675px;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    margin-bottom: 80px;
  }
`;

const Title = styled("h2")`
  color: ${({ theme }) => (theme as any).colors.primaryLight};
  font-size: 24px;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    text-align: center;
    text-align: left;
    font-size: 36px;
    margin-top: 80px;
    margin-bottom: 48px;
  }
`;

const Paragraph = styled("p")`
  color: ${({ theme }) => (theme as any).colors.baseFontColor};
  font-size: 20px;
  margin-bottom: 32px;
  line-height: 3.6rem;
`;

const About = () => (
  <Box width="100%" textAlign="left">
    <Container>
      <Title data-testid="h2-about-fllite">About Fllite</Title>
      <Paragraph data-testid="p-paragraph-about-fllite">
        Fllite is a fully accredited charter flight booking platform that
        connects passengers with thousands of private aircraft across the US and
        beyond. Founded by real pilots and aviation enthusiasts, we are
        committed to making the private flying experience as seamless,
        efficient, and memorable as possible.
      </Paragraph>
    </Container>
  </Box>
);

export default About;
