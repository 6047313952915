const allowedCharacters = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "0",
  ".",
];

export const priceStringToNumber = (price: string) =>
  Number(priceStringToSaveString(price));

export const priceStringToSaveString = (price: string): string =>
  String(price)
    .split("")
    .reduce(
      (acc, curr) => (allowedCharacters.includes(curr) ? acc + curr : acc),
      "",
    );
