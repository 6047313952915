/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";

import styled from "@emotion/styled";
import Link from "next/link";

import { scrollToTarget } from "@fllite/ui/utils";

const RequestAFlightButtonStyled = styled("a")`
  white-space: nowrap;
  border: 0 none;
  background: ${({ theme }) => (theme as any).colors.secondary}
    ${({ theme }) => (theme as any).gradients.secondary};
  border-radius: ${({ theme }) => (theme as any).radius.buttonRadius};
  color: ${({ theme }) => (theme as any).colors.baseFontColor};
  font-size: 14px;
  font-weight: 600;
  padding: 8px 64px;
  cursor: pointer;
`;

interface RequestAFlightButtonProps {
  href?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e: any) => void;
}

export const RequestAFlightButton: FC<RequestAFlightButtonProps> = ({
  href,
  onClick,
}) => {
  const handleScrollTo = (e: any) => {
    e.preventDefault();
    scrollToTarget("raf");
  };

  return (
    <Link href={href || "/#raf-form"} passHref legacyBehavior>
      <RequestAFlightButtonStyled
        data-testid="button-raf-jump"
        onClick={onClick ? onClick : !href ? handleScrollTo : undefined}
      >
        Request a flight
      </RequestAFlightButtonStyled>
    </Link>
  );
};
