import { FC } from "react";

import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";

interface CircleProps {
  radius?: number;
  className?: string;
  pulsing?: boolean;
}

const pulseAnimation = keyframes`
	0%  {
		transform: scale(0.75);
		opacity: 0.2;
		}
		50%  {
		opacity: 0.2;
		}

  100% {
		transform: scale(1);
		opacity: 0;
		}
`;

const StyledCircle = styled("svg")<Pick<CircleProps, "pulsing">>`
  max-width: 100%;
  max-height: 100%;
  opacity: 0.2;
  pointer-events: none;
  overflow: visible;

  animation: ${({ pulsing }) =>
    pulsing &&
    css`
      ${pulseAnimation} 3s ease infinite
    `};
`;

const CircleWrapper = styled("div")<Pick<CircleProps, "radius">>`
  width: ${({ radius }) => `${(radius ?? 0) * 2}px`};
  height: ${({ radius }) => `${(radius ?? 0) * 2}px`};
  pointer-events: none;
  overflow: visible;
`;

export const Circle: FC<CircleProps> = ({ radius, className, pulsing }) => (
  <CircleWrapper className={className} radius={radius}>
    <StyledCircle
      height={(radius ?? 0) * 2}
      width={(radius ?? 0) * 2}
      viewBox={`0 0 ${(radius ?? 0) * 2} ${(radius ?? 0) * 2}`}
      pulsing={pulsing}
    >
      <circle
        cx={radius}
        cy={radius}
        r={(radius ?? 0) - 1}
        stroke="#8cc0ff"
        strokeWidth="1"
        fill="transparent"
      />
    </StyledCircle>
  </CircleWrapper>
);
