/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useRef, useState } from "react";

import styled from "@emotion/styled";
import { useRouter } from "next/router";

import { faqItems } from "@fllite/ui/components";
import { SectionAccordion } from "@fllite/ui/components";
import { SectionTabs } from "@fllite/ui/components";

interface TitleProps {
  onLightBackground?: boolean;
}
const Title = styled("h2", {
  shouldForwardProp: (prop) => !["onLightBackground"].includes(prop),
})<TitleProps>`
  color: ${({ theme, onLightBackground }) =>
    onLightBackground
      ? (theme as any).colors.primaryDarkest
      : (theme as any).colors.baseFontColor};
  font-size: 24px;
  text-align: left;
  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    text-align: center;
    margin-bottom: 36px;
    font-size: 36px;
  }
`;

const Container = styled("div")`
  text-align: center;
  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    margin-bottom: 100px;
  }
`;

interface FAQProps {
  onLightBackground?: boolean;
}

export const FAQ: FC<FAQProps> = ({ onLightBackground }) => {
  const faqRef = useRef(null);
  const router = useRouter();

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (router.asPath.includes("#faq")) {
      timer = setTimeout(
        () =>
          (faqRef.current as any)?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          }),
        500,
      );
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const [selectedSection, setSelectedSection] = useState(faqItems[0]!.id);

  const handleSelectSection = (sectionId: string) => {
    setSelectedSection(sectionId);
  };

  const section = faqItems.find((item) => item.id === selectedSection);

  return (
    <Container ref={faqRef}>
      <Title data-testid="h2-faq" onLightBackground={onLightBackground}>
        Frequently asked questions
      </Title>
      <SectionTabs
        handleSelectSection={handleSelectSection}
        selectedSection={selectedSection}
        onLightBackground={onLightBackground}
      />
      <SectionAccordion section={section!} publicPage />
    </Container>
  );
};
