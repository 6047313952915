/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";
import * as React from "react";

import styled from "@emotion/styled";
import { Circle } from "@/components/Circle";

import { H2, Paragraph, Text } from "@fllite/ui/components";

interface FooterClaimProps {
  title: React.ReactNode;
  subTitle: React.ReactNode;
  description: React.ReactNode;
}

const Container = styled("div")`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    flex-direction: row;
    margin-bottom: 30px;
    flex-direction: column;
    max-width: 263px;
  }
`;

const SubTitle = styled(Text)`
  font-size: 18px;
  height: 0;
`;

const CircleContainer = styled("div")`
  position: relative;
  width: 146px;
  height: 146px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 8px;
  flex-shrink: 0;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    margin-bottom: 20px;
  }

  ${H2} {
    line-height: 1;
    font-size: 32px;

    ${({ theme }) => (theme as any).breakpoints.up("md")} {
      font-size: 34px;
    }
  }
`;

const Description = styled("div")`
  text-align: center;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    margin-left: 20px;
    margin-left: 0;
  }
`;

const StyledCircle = styled(Circle)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  pointer-events: none;
`;

export const ClaimItem: FC<FooterClaimProps> = ({
  title,
  subTitle,
  description,
}) => (
  <Container>
    <CircleContainer>
      <H2 primaryLight>{title}</H2>
      <SubTitle primaryLight>{subTitle}</SubTitle>
      <StyledCircle radius={73} pulsing />
      <StyledCircle radius={50} pulsing />
      <StyledCircle radius={19} pulsing />
    </CircleContainer>

    <Description>
      <Paragraph>{description}</Paragraph>
    </Description>
  </Container>
);
