/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from "@emotion/styled";
import { Box, Stack } from "@mui/material";
import Image from "next/image";
import Link from "next/link";

import { Button } from "@fllite/ui/components";

const Title = styled("h2")`
  color: ${({ theme }) => (theme as any).colors.primaryLight};
  font-size: 24px;
  max-width: 500px;

  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    text-align: center;
    text-align: left;
    font-size: 36px;
    margin-top: 0;
    margin-bottom: 48px;
  }
`;

const Paragraph = styled("p")`
  color: ${({ theme }) => (theme as any).colors.baseFontColor};
  font-size: 20px;
  margin-bottom: 32px;
  line-height: 3.6rem;
  max-width: 500px;
`;

const ImageBox = styled(Image)`
  border-radius: ${({ theme }) => (theme as any).radius.smallBoxRadius};
  max-width: 100%;
  height: auto;
  order: 1;
  ${({ theme }) => (theme as any).breakpoints.up("md")} {
    order: 2;
  }
`;

const StyledButton = styled(Button)`
  background-color: white;

  &:hover {
    background-color: white;
  }
`;

const Content = styled(Box)`
  max-width: ${({ theme }) => (theme as any).dimensions.pageWidth};
  margin: auto;
  width: 100%;
`;

const AircraftOwnership = () => (
  <Content>
    <Stack
      spacing={2}
      justifyContent="space-between"
      width="100%"
      direction={{ xs: "column", md: "row" }}
      mb={{ xs: 3, md: 14 }}
      alignItems="flex-start"
    >
      <Box component="article" order={{ xs: 2, md: 1 }}>
        <Title data-testid="h2-aircraft-ownership">
          Interested in aircraft ownership?
        </Title>
        <Paragraph data-testid="p-paragraph-aircraft-ownership">
          Sharing the cost and responsibility for an aircraft is an attractive
          arrangement for many aircraft owners. Read about co-ownership
          agreements and decide if this might work for you.
        </Paragraph>
        <Link href="/contact-us?subject=Aircraft Ownership interest" passHref>
          <StyledButton outlinePrimary>Contact us</StyledButton>
        </Link>
      </Box>
      <ImageBox
        src="/assets/aircraft_ownership.webp"
        alt="aircraft photo"
        width={497}
        height={332}
      />
    </Stack>
  </Content>
);

export default AircraftOwnership;
